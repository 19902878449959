import { useState } from "react";

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Toolbar from '@mui/material/Toolbar';

import logo from './GDSLogo64.png';
import './App.css';

import Home from "./Home";
import MapPage from "./MapPage";
import Licensing from "./Licensing";
import Purchases from "./Purchases";
import Customers from './Customers'

function App() {
    const HomeTabIndex = 0;
    const MapPageTabIndex = 1;
    const LicensingTabIndex = 2;
    const PurchasesTabIndex = 3;
    const CustomersTabIndex = 4;

    const [sTabValue, setTabValue] = useState(1);

    function onTabValueChange(e, newVal) {
        setTabValue(newVal);
    }

    function renderFooter() {
        return (
            <p className="App-footer"> Copyright @ GDS Design & Development Ltd. 2012-{(new Date()).getFullYear()}</p>
        );
    }

    return (
        <div className="App">
            <header className="App-header">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <TableCell />
                            <TableCell align="right">
                                <img src={logo} className="App-logo" alt="logo" />
                            </TableCell>
                            <TableCell align="center">
                                <h1 className="App-link">GDS Design & Development Ltd.</h1>
                            </TableCell>
                            <TableCell />
                            <TableCell>
                                <p className="App-header-text">Email: info@gdsdesigndevelopment.com</p>
                                <p className="App-header-text">Phone: (403) 608 3868</p>
                                <p className="App-header-text">Calgary, Alberta, Canada</p>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                </Table>
            </header>
            <AppBar position="static">
                <Toolbar>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={sTabValue} textColor="secondary" indicatorColor="secondary" onChange={onTabValueChange}>
                            <Tab value={HomeTabIndex} label="Home" />
                            <Tab value={MapPageTabIndex} label="GDS Google Map" />
                            <Tab value={LicensingTabIndex} label="Licensing" />
                            <Tab value={PurchasesTabIndex} label="Buy Now" />
                            <Tab value={CustomersTabIndex} label="Our Customers" />
                        </Tabs>
                    </Box>
                </Toolbar>
            </AppBar>
            <br/>
            <Box hidden={!(sTabValue === HomeTabIndex)}>
                <Home />
            </Box>
            <Box hidden={!(sTabValue === MapPageTabIndex)}>
                <MapPage />
            </Box>
            <Box hidden={!(sTabValue === LicensingTabIndex)}>
                <Licensing />
            </Box>
            <Box hidden={!(sTabValue === PurchasesTabIndex)}>
                <Purchases />
            </Box>
            <Box hidden={!(sTabValue === CustomersTabIndex)}>
                <Customers />
            </Box>
            <br />
            <AppBar position="static">
                <Toolbar>
                    {renderFooter()}
                </Toolbar>
            </AppBar>
        </div>
    );
}

export default App;
