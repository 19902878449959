import EndUserLicenseAgreementPath from './LicenseAgreement/GdsEndUserLicenseAgreement.txt'

const DownloadStatusSuccess = 0;
const DownloadStatusFailedToDownload = 1;
const DownloadStatusInvalidLicenseKey = 2;
const DownloadStatusTooManyDownloadsPerLicenseKey = 3;
const DownloadStatusTooManyConcurrentDownloads = 4;
const DownloadStatusDownloadEntryNotExist = 5;
const DownloadStatusInvalidDownloadToken = 6;
const DownloadStatusNoProductIsFound = 7;

const DownloadStatusTexts = [
    "Success.",
    "Failed to download.",
    "Failed to download - invalid license key.",
    "Failed to download - too many download requests per license key.",
    "Failed to download - too many concurrent download requests.",
    "Failed to download - download entry not exist.",
    "Failed to download - invalid download token.",
    "Failed to download - no product is found."
];

const TrialLicenseRegistrationStatusSuccess = 0;
const TrialLicenseRegistrationStatusFailedToRegister = 1;
const TrialLicenseRegistrationStatusTooManyRegistrationsPerDay = 2;
const TrialLicenseRegistrationStatusTooManyRegistrationsPerIpPerDay = 3;
const TrialLicenseRegistrationStatusRegistrationExists = 4;
const TrialLicenseRegistrationStatusFailedToGenerateRegistrationConfirmationCode = 5;
const TrialLicenseRegistrationStatusFailedtoCreateEmail = 6;
const TrialLicenseRegistrationStatusFailedToSendEmail = 7;

const TrialLicenseRegistrationStatusTexts = [
    'Sucessfully submitted trial license registration request.',
    'Failed to register trial license.',
    'Failed to register trial license - to too many registrations in a day.',
    'Failed to register trial license - too many registrations from the same ip address in a day.',
    'Failed to register trial license - same registration existed.',
    'Failed to generate trial license registration confirmation code.',
    'Failed to create trial license registration confirmation email.',
    'Failed to send trail license registration confirmation email.'
];

const TrialLicenseRegistrationErrorText = 'Failed to submit trial license registration request.';

const TrialLicenseNewStatusSuccess = 0;
const TrialLicenseNewStatusProductDoesNotExist = 1;
const TrialLicenseNewStatusPreviouslyRegisteredWithinThreeMonths = 2;
const TrialLicenseNewStatusContactSupportError = 3;

const TrialLicenseNewStatusTexts = [
    'Successfully complete trial license registration, please check your email to retrieve the trial license key.',
    'Failed to complete trial license registration because product does not exist.',
    'Failed to complete trial license registration because one email can register only one product once every three months.',
    'Failed to complete trial license registration, please contact support for more details.'
];

const TrialLicenseNewErrorText = 'Failed to complete trial license registration.';

function readEndUserLicenseAgreement() {
    const readLicenseAgreementRequest = new XMLHttpRequest();

    readLicenseAgreementRequest.onreadystatechange = function () {
        if (readLicenseAgreementRequest.readyState === 4 && readLicenseAgreementRequest.status === 200) {
            if (readLicenseAgreementRequest.responseText != null) {
                DownloadFunctions.EndUserLicenseAgreementTexts = readLicenseAgreementRequest.responseText.split('\n');
            }
        }
    }

    readLicenseAgreementRequest.open('GET', EndUserLicenseAgreementPath);
    readLicenseAgreementRequest.send();
}

function startDownload(licenseKey) {
    let xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
        if (this.readyState === 4 && this.status === 200) {
            let responseTextLower = this.responseText.toLowerCase();
            if (responseTextLower !== DownloadFunctions.NaString && responseTextLower !== DownloadFunctions.NullString && responseTextLower.length > 0) {
                let downloadResponse = JSON.parse(this.responseText);

                switch (downloadResponse.status) {
                    case DownloadStatusSuccess:
                        DownloadFunctions.DownloadToken = downloadResponse.downloadToken;
                        DownloadFunctions.showProductsPage(downloadResponse.clientName, downloadResponse.fileNames);
                        DownloadFunctions.setStartDownloadError('');
                        break;
                    case DownloadStatusFailedToDownload:
                    case DownloadStatusInvalidLicenseKey:
                    case DownloadStatusTooManyDownloadsPerLicenseKey:
                    case DownloadStatusTooManyConcurrentDownloads:
                    case DownloadStatusDownloadEntryNotExist:
                    case DownloadStatusInvalidDownloadToken:
                    case DownloadStatusNoProductIsFound:
                        DownloadFunctions.setStartDownloadError(DownloadStatusTexts[downloadResponse.status]);
                        break;
                    default:
                        DownloadFunctions.setStartDownloadError(DownloadStatusTexts[DownloadStatusFailedToDownload]);
                        break;
                }
            }
            else {
                DownloadFunctions.setStartDownloadError(DownloadStatusTexts[DownloadStatusFailedToDownload]);
            }
        }
        else {
            DownloadFunctions.setStartDownloadError(DownloadStatusTexts[DownloadStatusFailedToDownload]);
        }
    };

    let url = DownloadFunctions.ServerUrl + 'DownloadStart';

    xhttp.open("POST", url, true);
    xhttp.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');

    let reqObj = {
        LicenseKey: licenseKey
    };
    xhttp.send(JSON.stringify(reqObj));
}

function getLicenseAgreementToken(licenseKey) {
    // check to make sure that license key and download token are not empty
    if (licenseKey.length < 10 || DownloadFunctions.DownloadToken.length < 10) {
        return;
    }

    // check to make sure that license agreement token is empty, if it is not empty, that means that
    // the user already got the license agreement token, so no need to do it again, instead, just doDownload
    if (DownloadFunctions.LicenseAgreementToken.length > 10) {
        DownloadFunctions.setOtherDownloadError('');
        //        doDownload(licenseKey);
        completeDownload(licenseKey);
        return;
    }

    let xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
        if (this.readyState === 4 && this.status === 200) {
            let responseTextLower = this.responseText.toLowerCase();
            if (responseTextLower !== DownloadFunctions.NaString && responseTextLower !== DownloadFunctions.NullString && responseTextLower.length > 0) {
                let agreeLicenseResponse = JSON.parse(this.responseText);

                switch (agreeLicenseResponse.status) {
                    case DownloadStatusSuccess:
                        DownloadFunctions.LicenseAgreementToken = agreeLicenseResponse.licenseAgreementToken;
                        DownloadFunctions.setOtherDownloadError('');
//                        doDownload(licenseKey);
                        completeDownload(licenseKey);
                        break;
                    case DownloadStatusDownloadEntryNotExist:
                        DownloadFunctions.setOtherDownloadError("Your session expired");
                        // put on session expires
                        break;
                    default:
                        DownloadFunctions.setOtherDownloadError(DownloadStatusTexts[DownloadStatusFailedToDownload]);
                        break;
                }
            }
            else {
                DownloadFunctions.setOtherDownloadError(DownloadStatusTexts[DownloadStatusFailedToDownload]);
            }
        }
        else {
            DownloadFunctions.setOtherDownloadError(DownloadStatusTexts[DownloadStatusFailedToDownload]);
        }
    };

    let url = DownloadFunctions.ServerUrl + 'DownloadAgreeLicense';

    xhttp.open("POST", url, true);
    xhttp.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');

    let reqObj = {
        LicenseKey: licenseKey,
        DownloadToken: DownloadFunctions.DownloadToken
    };
    xhttp.send(JSON.stringify(reqObj));
}

function doDownload(licenseKey) {
    if (licenseKey.length < 10 || DownloadFunctions.DownloadToken.length < 10 ||
        DownloadFunctions.LicenseAgreementToken.length < 10 || DownloadFunctions.FileNameToDownload.length < 10) {
        return;
    }

    var dlUrl = DownloadFunctions.ServerUrl + 'Download/' + licenseKey + '/' + DownloadFunctions.DownloadToken + '/' + DownloadFunctions.LicenseAgreementToken + '/' + DownloadFunctions.FileNameToDownload;
    window.open(dlUrl, '_blank', '');
}

function completeDownload(licenseKey) {
    if (licenseKey.length < 10 || DownloadFunctions.DownloadToken.length < 10 ||
        DownloadFunctions.LicenseAgreementToken.length < 10 || DownloadFunctions.FileNameToDownload.length < 10) {
        return;
    }

    let xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
        if (this.readyState === 4 && this.status === 200) {
            var blob = new Blob([this.response], { type: 'application/octet-stream' });
            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = DownloadFunctions.FileNameToDownload;
            link.click();


//            var blob = new Blob([request.response], { type: 'application/pdf' });
//            var link = document.createElement('a');
//            link.href = window.URL.createObjectURL(blob);
//            link.download = filename;

//            document.body.appendChild(link);

//            link.click();

//            document.body.removeChild(link);


//            alert(this.response);
/*            let responseTextLower = this.responseText.toLowerCase();
            if (responseTextLower !== DownloadFunctions.NaString && responseTextLower !== DownloadFunctions.NullString && responseTextLower.length > 0) {
                let downloadResponse = JSON.parse(this.responseText);

                switch (downloadResponse.status) {
                    case DownloadStatusSuccess:
                        DownloadFunctions.DownloadToken = downloadResponse.downloadToken;
                        DownloadFunctions.showProductsPage(downloadResponse.clientName, downloadResponse.fileNames);
                        DownloadFunctions.setStartDownloadError('');
                        break;
                    case DownloadStatusFailedToDownload:
                    case DownloadStatusInvalidLicenseKey:
                    case DownloadStatusTooManyDownloadsPerLicenseKey:
                    case DownloadStatusTooManyConcurrentDownloads:
                    case DownloadStatusDownloadEntryNotExist:
                    case DownloadStatusInvalidDownloadToken:
                    case DownloadStatusNoProductIsFound:
                        DownloadFunctions.setStartDownloadError(DownloadStatusTexts[downloadResponse.status]);
                        break;
                    default:
                        DownloadFunctions.setStartDownloadError(DownloadStatusTexts[DownloadStatusFailedToDownload]);
                        break;
                }
            }
            else {
                DownloadFunctions.setStartDownloadError(DownloadStatusTexts[DownloadStatusFailedToDownload]);
            }
*/        }
        else {
            DownloadFunctions.setOtherDownloadError(DownloadStatusTexts[DownloadStatusFailedToDownload]);
        }
    };

    let url = DownloadFunctions.ServerUrl + 'DownloadComplete';

    xhttp.open("POST", url, true);
    xhttp.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
    xhttp.responseType = 'blob';

    let reqObj = [
        licenseKey,
        DownloadFunctions.DownloadToken,
        DownloadFunctions.LicenseAgreementToken,
        DownloadFunctions.FileNameToDownload
    ];
    xhttp.send(JSON.stringify(reqObj));
}

function registerTrialLicense(productId, productName, email) {
    let xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
        if (this.readyState === 4 && this.status === 200) {
            let responseTextLower = this.responseText.toLowerCase();
            if (responseTextLower !== DownloadFunctions.NaString && responseTextLower !== DownloadFunctions.NullString && responseTextLower.length > 0) {
                let status = parseInt(this.responseText);

                switch (status) {
                    case TrialLicenseRegistrationStatusSuccess:
                        DownloadFunctions.showCompleteRegistration();
                        break;
                    case TrialLicenseRegistrationStatusFailedToRegister:
                    case TrialLicenseRegistrationStatusTooManyRegistrationsPerDay:
                    case TrialLicenseRegistrationStatusTooManyRegistrationsPerIpPerDay:
                    case TrialLicenseRegistrationStatusRegistrationExists:
                    case TrialLicenseRegistrationStatusFailedToGenerateRegistrationConfirmationCode:
                    case TrialLicenseRegistrationStatusFailedtoCreateEmail:
                    case TrialLicenseRegistrationStatusFailedToSendEmail:
                        DownloadFunctions.setRegisterError(TrialLicenseRegistrationStatusTexts[status]);
                        break;
                    default:
                        DownloadFunctions.setRegisterError(TrialLicenseRegistrationErrorText);
                        break;
                }
            }
            else {
                DownloadFunctions.setRegisterError(TrialLicenseRegistrationErrorText);
            }
        }
        else {
            DownloadFunctions.setRegisterError(TrialLicenseRegistrationErrorText);
        }
    };

    let url = DownloadFunctions.ServerUrl + 'TrialLicenseRegister';

    xhttp.open("POST", url, true);
    xhttp.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');

    let reqObj = {
        ProductId: productId,
        ProductName: productName,
        Email: email
    };
    xhttp.send(JSON.stringify(reqObj));
}

function completeTrialLicenseRegistration(registrationKey) {
    let xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
        if (this.readyState === 4 && this.status === 200) {
            let status = parseInt(this.responseText);

            switch (status) {
                case TrialLicenseNewStatusSuccess:
                case TrialLicenseNewStatusProductDoesNotExist:
                case TrialLicenseNewStatusPreviouslyRegisteredWithinThreeMonths:
                case TrialLicenseNewStatusContactSupportError:
                    DownloadFunctions.setCompleteRegistrationError(TrialLicenseNewStatusTexts[status]);
                    break;
                default:
                    DownloadFunctions.setCompleteRegistrationError(TrialLicenseNewErrorText);
                    break;
            }
        }
        else {
            DownloadFunctions.setCompleteRegistrationError(TrialLicenseNewErrorText);
        }
    };

    let url = DownloadFunctions.ServerUrl + 'TrialLicenseCompleteRegistration';

    xhttp.open("POST", url, true);
    xhttp.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');

    let reqObj = {
        RegistrationKey: registrationKey
    };
    xhttp.send(JSON.stringify(reqObj));
}

const DownloadFunctions = {
    NaString: 'n/a',
    NullString: 'null',
    EndUserLicenseAgreementTexts: [],
    DownloadToken: '',
    LicenseAgreementToken: '',
    FileNameToDownload: '',

    ServerUrl: window.location.href + 'api/Service/',

    readEndUserLicenseAgreement: readEndUserLicenseAgreement,

    startDownload: startDownload,
    getLicenseAgreementToken: getLicenseAgreementToken,

    registerTrialLicense: registerTrialLicense,
    completeTrialLicenseRegistration: completeTrialLicenseRegistration,

    showProductsPage: function () { },
    setStartDownloadError: function () { },
    setOtherDownloadError: function () { },

    showCompleteRegistration: function () { },
    setRegisterError: function () { },
    setCompleteRegistrationError: function () { }
}

export default DownloadFunctions;
