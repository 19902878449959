import './App.css';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import screencutsImg00 from './images/ScreenCut00.png';
import screencutsImg01 from './images/ScreenCut01.png';
import screencutsImg02 from './images/ScreenCut02.jpg';
import screencutsImg03 from './images/ScreenCut03.jpg';
import screencutsImg04 from './images/ScreenCut04.png';
import screencutsImg05 from './images/ScreenCut05.png';
import screencutsImg06 from './images/ScreenCut06.png';
import screencutsImg07 from './images/ScreenCut07.jpg';
import screencutsImg08 from './images/ScreenCut08.png';
import screencutsImg09 from './images/ScreenCut09.png';

import screencutsImg10 from './images/ScreenCut10.png';
import screencutsImg11 from './images/ScreenCut11.png';
import screencutsImg12 from './images/ScreenCut12.jpg';
import screencutsImg13 from './images/ScreenCut13.png';
import screencutsImg14 from './images/ScreenCut14.png';
import screencutsImg15 from './images/ScreenCut15.png';
import screencutsImg16 from './images/ScreenCut16.png';
import screencutsImg17 from './images/ScreenCut17.png';
import screencutsImg18 from './images/ScreenCut18.png';
import screencutsImg19 from './images/ScreenCut19.png';

import screencutsImg20 from './images/ScreenCut20.png';
import screencutsImg21 from './images/ScreenCut21.png';
import screencutsImg22 from './images/ScreenCut22.png';
import screencutsImg23 from './images/ScreenCut23.png';
import screencutsImg24 from './images/ScreenCut24.png';
import screencutsImg25 from './images/ScreenCut25.png';
import screencutsImg26 from './images/ScreenCut26.png';
import screencutsImg27 from './images/ScreenCut27.png';
import screencutsImg28 from './images/ScreenCut28.png';
import screencutsImg29 from './images/ScreenCut29.png';

import screencutsImg30 from './images/ScreenCut30.png';

function MapPageScreencuts() {

    return (
        <div>
            <Card>
                <CardContent >
                    <br />
                    <img src={screencutsImg00} alt={screencutsImg00} style={{ width: 1024, height: 660 }} />
                    <p className="Screencuts-label">Figure 1. Char Pins with two different display settings</p>
                    <br />
                    <img src={screencutsImg01} alt={screencutsImg01} style={{ width: 1024, height: 662 }} />
                    <p className="Screencuts-label">Figure 2. Ball Pins with two different display settings on Satellite map, one has transparent fill color</p>
                    <br />
                    <img src={screencutsImg02} alt={screencutsImg02} style={{ width: 1024, height: 682 }} />
                    <p className="Screencuts-label">Figure 3. Various legacy pins and display settings</p>
                    <br />
                    <img src={screencutsImg03} alt={screencutsImg03} style={{ width: 1024, height: 682 }} />
                    <p className="Screencuts-label">Figure 4. Legacy pin highlight display settings and show description</p>
                    <br />
                    <img src={screencutsImg04} alt={screencutsImg04} style={{ width: 1024, height: 535 }} />
                    <p className="Screencuts-label">Figure 5. Various icons</p>
                    <br />
                    <img src={screencutsImg05} alt={screencutsImg05} style={{ width: 1024, height: 535 }} />
                    <p className="Screencuts-label">Figure 6. Various icons and highlighted icon</p>
                    <br />
                    <img src={screencutsImg06} alt={screencutsImg06} style={{ width: 1024, height: 535 }} />
                    <p className="Screencuts-label">Figure 7. Various polylines with different display settings</p>
                    <br />
                    <img src={screencutsImg07} alt={screencutsImg07} />
                    <p className="Screencuts-label">Figure 8. Dynamically creating a polygon through mouse clicks</p>
                    <br />
                    <img src={screencutsImg08} alt={screencutsImg08} style={{ width: 1024, height: 535 }} />
                    <p className="Screencuts-label">Figure 9. Polygons with transparent fill color, one of which has multiple rings (holes in polygon)</p>
                    <br />
                    <img src={screencutsImg09} alt={screencutsImg09} style={{ width: 1024, height: 535 }} />
                    <p className="Screencuts-label">Figure 10. Green polygon in Edit mode</p>
                    <br />
                    <img src={screencutsImg10} alt={screencutsImg10} style={{ width: 1024, height: 535 }} />
                    <p className="Screencuts-label">Figure 11. Polygon ZIndex change (before)</p>
                    <br />
                    <img src={screencutsImg11} alt={screencutsImg11} style={{ width: 1024, height: 535 }} />
                    <p className="Screencuts-label">Figure 12. Polygon ZIndex change (after)</p>
                    <br />
                    <img src={screencutsImg12} alt={screencutsImg12} />
                    <p className="Screencuts-label">Figure 13. Different polygons with or without texts</p>
                    <br />
                    <img src={screencutsImg13} alt={screencutsImg13} style={{ width: 1024, height: 535 }} />
                    <p className="Screencuts-label">Figure 14. Heatmaps on satellite map</p>
                    <br />
                    <img src={screencutsImg14} alt={screencutsImg14} style={{ width: 1024, height: 535 }} />
                    <p className="Screencuts-label">Figure 15. Heatmaps with different display settings</p>
                    <br />
                    <img src={screencutsImg15} alt={screencutsImg15} style={{ width: 1024, height: 535 }} />
                    <p className="Screencuts-label">Figure 16. Circles</p>
                    <br />
                    <img src={screencutsImg16} alt={screencutsImg16} style={{ width: 1024, height: 535 }} />
                    <p className="Screencuts-label">Figure 17. Rectangles, two of which in Edit mode</p>
                    <br />
                    <img src={screencutsImg17} alt={screencutsImg17} style={{ width: 1024, height: 460 }} />
                    <p className="Screencuts-label">Figure 18. KML Layer on the map</p>
                    <br />
                    <img src={screencutsImg18} alt={screencutsImg18} style={{ width: 1024, height: 460 }} />
                    <p className="Screencuts-label">Figure 19. Message display on KML Layer mouse click and metadata display on KML layer</p>
                    <br />
                    <img src={screencutsImg19} alt={screencutsImg19} style={{ width: 1024, height: 631 }} />
                    <p className="Screencuts-label">Figure 20. Geocoding results displayed on the map (left) and map image (right)</p>
                    <br />
                    <img src={screencutsImg20} alt={screencutsImg20} style={{ width: 1024, height: 491 }} />
                    <p className="Screencuts-label">Figure 21. Geo JSON Layer</p>
                    <br />
                    <img src={screencutsImg21} alt={screencutsImg21} style={{ width: 1024, height: 491 }} />
                    <p className="Screencuts-label">Figure 22. Highlighted feature in Geo JSON Layer</p>
                    <br />
                    <img src={screencutsImg22} alt={screencutsImg22} style={{ width: 1024, height: 491 }} />
                    <p className="Screencuts-label">Figure 23. Removed feature in Geo JSON Layer</p>
                    <br />
                    <img src={screencutsImg23} alt={screencutsImg23} style={{ width: 1024, height: 491 }} />
                    <p className="Screencuts-label">Figure 24. Different style in Geo JSON Layer</p>
                    <br />
                    <img src={screencutsImg24} alt={screencutsImg24} style={{ width: 1024, height: 490 }} />
                    <p className="Screencuts-label">Figure 25. Manual displayed routes without using waypoints and driving instructions with un-filtered HTML tags as the results of directions service</p>
                    <br />
                    <img src={screencutsImg25} alt={screencutsImg25} style={{ width: 1024, height: 490 }} />
                    <p className="Screencuts-label">Figure 26. Auto displayed routes using waypoints and driving instructions with un-filtered HTML tags as the results of directions service </p>
                    <br />
                    <img src={screencutsImg26} alt={screencutsImg26} style={{ width: 1024, height: 536 }} />
                    <p className="Screencuts-label">Figure 27. Location elevation service and path evelation service</p>
                    <br />
                    <img src={screencutsImg27} alt={screencutsImg27} style={{ width: 1024, height: 582 }} />
                    <p className="Screencuts-label">Figure 28. Mouse over a pin to show descriptions as results of place services</p>
                    <br />
                    <img src={screencutsImg28} alt={screencutsImg28} style={{ width: 1024, height: 582 }} />
                    <p className="Screencuts-label">Figure 29. Computation results of geometry services</p>
                    <br />
                    <img src={screencutsImg29} alt={screencutsImg29} style={{ width: 1024, height: 536 }} />
                    <p className="Screencuts-label">Figure 30. Icon pins on a German culture map</p>

                    <br />
                    <img src={screencutsImg30} alt={screencutsImg30} />
                    <p className="Screencuts-label">Figure 31. Street View</p>
                </CardContent>
            </Card>
        </div>
    );
}

export default MapPageScreencuts;
