import './App.css';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

function Home() {

    return (
        <div  >
            <Card>
                <CardContent >
                    <br/>
                    <p className="Purchase-paypal-note">
                        GDS Design & Development Ltd. is a software product, solution and consulting company which locates in Calgary, Alberta, Canada.
                    </p>
                    <br />
                </CardContent>
            </Card>
            <br />
        </div>
    );
}

export default Home;
