import './App.css';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import clientImg01 from './images/ClientAccucom.jpg'
import clientImg02 from './images/ClientAim.jpg'
import clientImg03 from './images/ClientAviTone.jpg'
import clientImg04 from './images/ClientCarisBrooke.jpg'
import clientImg05 from './images/ClientCgSupply.jpg'
import clientImg06 from './images/ClientComputerFrontiers.jpg'
import clientImg07 from './images/ClientDentedDev.jpg'
import clientImg08 from './images/ClientDHI.jpg'
import clientImg09 from './images/ClientDME.jpg'
import clientImg10 from './images/ClientEngHouse.jpg'
import clientImg11 from './images/ClientFastTrackEMS.jpg'
import clientImg12 from './images/ClientFedEx.jpg'
import clientImg13 from './images/ClientKone.jpg'
import clientImg14 from './images/ClientLambertCable.jpg'
import clientImg15 from './images/ClientLinkSoft.jpg'
import clientImg16 from './images/ClientLornIT.jpg'
import clientImg17 from './images/ClientMidea.jpg'
import clientImg18 from './images/ClientNationEx.jpg'
import clientImg19 from './images/ClientRbh.jpg'
import clientImg20 from './images/ClientRfe.jpg'
import clientImg21 from './images/ClientRtm.jpg'
import clientImg22 from './images/ClientSingleSource.jpg'
import clientImg23 from './images/ClientSvc.jpg'
import clientImg24 from './images/ClientUniSystems.jpg'

function Customers() {

    return (
        <div  >
            <Card>
                <CardContent >
                    <p className="Section-body">
                        GDS Google Map is widely used by customers ranging from individual professionals, start-ups to fortune 500 companies all over the world:
                    </p>
                    <br />
                    <p className="Section-body">
                        North America: Canada, United States and Mexico
                    </p>
                    <p className="Section-body">
                        South America: Colombia
                    </p>
                    <p className="Section-body">
                        Europe: Austria, Cyprus, Denmark, Germany, Malta, Portugal, Spain, Turkey and United Kingdom
                    </p>
                    <p className="Section-body">
                        Oceania: Australia and New Zealand
                    </p>
                    <p className="Section-body">
                        Asia: China (Mainland and Taiwan), Israel, South Korea and Vietnam
                    </p>
                    <br />
                    <p className="Section-body">
                        Here are some of them:
                    </p>
                    <br />

                    <table width="100%">
                        <tr>
                            <td><img className="Customers-Image" src={clientImg01} alt={clientImg01} /></td>
                            <td><img className="Customers-Image" src={clientImg02} alt={clientImg02} /></td>
                            <td><img className="Customers-Image" src={clientImg03} alt={clientImg03} /></td>
                            <td><img className="Customers-Image" src={clientImg04} alt={clientImg04} /></td>
                        </tr>
                        <tr>
                            <td><img className="Customers-Image" src={clientImg05} alt={clientImg05} /></td>
                            <td><img className="Customers-Image" src={clientImg06} alt={clientImg06} /></td>
                            <td><img className="Customers-Image" src={clientImg07} alt={clientImg07} /></td>
                            <td><img className="Customers-Image" src={clientImg08} alt={clientImg08} /></td>
                        </tr>
                        <tr>
                            <td><img className="Customers-Image" src={clientImg09} alt={clientImg09} /></td>
                            <td><img className="Customers-Image" src={clientImg10} alt={clientImg10} /></td>
                            <td><img className="Customers-Image" src={clientImg11} alt={clientImg11} /></td>
                            <td><img className="Customers-Image" src={clientImg12} alt={clientImg12} /></td>
                        </tr>
                        <tr>
                            <td><img className="Customers-Image" src={clientImg13} alt={clientImg13} /></td>
                            <td><img className="Customers-Image" src={clientImg14} alt={clientImg14} /></td>
                            <td><img className="Customers-Image" src={clientImg15} alt={clientImg15} /></td>
                            <td><img className="Customers-Image" src={clientImg16} alt={clientImg16} /></td>
                        </tr>
                        <tr>
                            <td><img className="Customers-Image" src={clientImg17} alt={clientImg17} /></td>
                            <td><img className="Customers-Image" src={clientImg18} alt={clientImg18} /></td>
                            <td><img className="Customers-Image" src={clientImg19} alt={clientImg19} /></td>
                            <td><img className="Customers-Image" src={clientImg20} alt={clientImg20} /></td>
                        </tr>
                        <tr>
                            <td><img className="Customers-Image" src={clientImg21} alt={clientImg21} /></td>
                            <td><img className="Customers-Image" src={clientImg22} alt={clientImg22} /></td>
                            <td><img className="Customers-Image" src={clientImg23} alt={clientImg23} /></td>
                            <td><img className="Customers-Image" src={clientImg24} alt={clientImg24} /></td>
                        </tr>
                    </table>
                </CardContent>
            </Card>
            <br />
        </div>
    );
}

export default Customers;
