import './App.css';

import { useState } from "react";

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import DownloadFunctions from './DownloadFunctions';

function MapPageDownload() {

    const ProductIdGdsGoogleMapWinForms = 1;
    const ProductIdGdsGoogleMapWpf = 2;
    const ProductNameGdsGoogleMapWinForms = 'GDS Google Map WinForms';
    const ProductNameGdsGoogleMapWpf = 'GDS Google Map WPF';

    const [sLicenseKey, setLicenseKey] = useState('');

    const [sShowProductsPage, setShowProductsPage] = useState(false);
    const [sDisableNextButton, setDisableNextButton] = useState(false);
    const [sStartDownloadError, setStartDownloadError] = useState('');

    const [sClientName, setClientName] = useState('');
    const [sFileNames, setFileNames] = useState([]);
    const [sOtherDownloadError, setOtherDownloadError] = useState('');

    const [sShowEndUserLicenseAgreementDialog, setShowEndUserLicenseAgreementDialog] = useState(false);

    const [sEmail, setEmail] = useState('');
    const [sConfirmedEmail, setConfirmedEmail] = useState('');
    const [sProductId, setProductId] = useState(ProductIdGdsGoogleMapWinForms);
    const [sDisableRegisterButton, setDisableRegisterButton] = useState(false);

    const [sRegistrationKey, setRegistrationKey] = useState('');
    const [sShowCompleteRegistrationUi, setShowCompleteRegistrationUi] = useState(false);
    const [sDisableGetKeyButton, setDisableGetKeyButton] = useState(false);

    const [sRegisterError, setRegisterError] = useState('');
    const [sCompleteRegistrationError, setCompletRegistrationError] = useState('');

    function onLicenseKeyChange(e) {
        setLicenseKey(e.target.value);
    }

    function onNextButtonClick() {
        if (sLicenseKey.length < 10) {
            setStartDownloadError("Invalid license key.");
            return;
        }

        setDisableNextButton(true);
        setStartDownloadError('');
        DownloadFunctions.startDownload(sLicenseKey);
    }

    function showProductsPage(clientName, fileNames) {
        setClientName(clientName);
        setFileNames(fileNames);
        setShowProductsPage(true);
    }

    function onDownloadButtonClick(e) {
        DownloadFunctions.FileNameToDownload = e.target.value;
        setShowEndUserLicenseAgreementDialog(true);
    }

    function agreeLicenseAgreementButtonClick() {
        setShowEndUserLicenseAgreementDialog(false);
        setOtherDownloadError('');
        DownloadFunctions.getLicenseAgreementToken(sLicenseKey);
    }

    function notAgreeLicenseAgreementButtonClick() {
        setShowEndUserLicenseAgreementDialog(false);
        setOtherDownloadError('');
    }

    function onEmailChange(e) {
        setEmail(e.target.value);
    }

    function onConfirmedEmailChange(e) {
        setConfirmedEmail(e.target.value);
    }

    function onProductIdChange(e) {
        setProductId(e.target.value);
    }

    function onRegistrationKeyChange(e) {
        setRegistrationKey(e.target.value);
    }

    function onRegisterButtonClick() {
        if (sEmail.length < 5 || sConfirmedEmail.length < 5) {
            setRegisterError("Please provide valid email and confirmed email.");
            return;
        }

        if (sEmail !== sConfirmedEmail) {
            setRegisterError("Email does not match confirmed email.");
            return;
        }

        let productName = sProductId === 1 ? ProductNameGdsGoogleMapWinForms : ProductNameGdsGoogleMapWpf;

        setDisableRegisterButton(true);
        DownloadFunctions.registerTrialLicense(sProductId, productName, sEmail);
    }

    function showCompleteRegistrationUi() {
        setShowCompleteRegistrationUi(true);
    }

    function onGetKeyButtonClick() {
        if (sRegistrationKey.length < 10) {
            setCompletRegistrationError("Please provide valid registration confirmation code.");
            return;
        }

        setDisableGetKeyButton(true);
        DownloadFunctions.completeTrialLicenseRegistration(sRegistrationKey);
    }

    DownloadFunctions.readEndUserLicenseAgreement();

    DownloadFunctions.showProductsPage = showProductsPage;
    DownloadFunctions.setStartDownloadError = setStartDownloadError;
    DownloadFunctions.setOtherDownloadError = setOtherDownloadError;

    DownloadFunctions.showCompleteRegistration = showCompleteRegistrationUi;
    DownloadFunctions.setRegisterError = setRegisterError;
    DownloadFunctions.setCompleteRegistrationError = setCompletRegistrationError;

    function renderLicenseInputPage() {
        return (
            <Card>
                <CardContent >
                    <table width={"100%"} cellpadding={"10"}>
                        <tr>
                            <th className="Features-table-header" colspan={"2"}>
                                Please enter your commercial license key or trial license key, then click next.
                            </th>
                        </tr>
                        <tr>
                            <td className="Features-table-body" width={"80%"}>
                                <TextField multiline fullWidth id="licenseKeyTextField" label="License Key" value={sLicenseKey} onChange={onLicenseKeyChange} />
                            </td>
                            <td width={"20%"}>
                                <Button variant="contained" disabled={sDisableNextButton} onClick={onNextButtonClick} >Next</Button>
                            </td>
                        </tr>
                        <tr>
                            <td width={"80%"} align={"left"} colSpan={"2"}>
                                <p style={{ color: "red", fontSize: "18px" }}>{sStartDownloadError}</p>
                            </td>
                            <td width={"20%"}>
                            </td>
                        </tr>
                    </table>
                </CardContent>
            </Card>
        );
    }

    function renderProductsPage() {
        return (
            <Card>
                <CardContent >
                    <table width={"100%"} cellpadding={"10"}>
                        <tr>
                            <th className="Features-table-header" colspan={"4"}>
                                Welcome, {sClientName}
                            </th>
                        </tr>
                        <tr>
                            <td align={"left"} colSpan={"4"}>
                                <h3 style={{ color: "red", fontSize: "18px" }}>Please do not share these assemblies.</h3>
                                <p style={{ color: "blue", fontSize: "18px" }}>
                                    All our previous releases which we publicly published have been hacked and stolen.
                                    Therefore, starting from Version 9, we only publish trial editions of our products
                                    on our website, which constain very limited functionalities. We hope to protect our
                                    products from the hackers in this way.
                                </p>
                                <p style={{ color: "blue", fontSize: "18px" }}>Thanks for your help to keep us stay in business.</p>
                            </td>
                        </tr>
                        {
                            sFileNames.map((fileName) => {
                                return (
                                    <tr>
                                        <td width={"20%"} />
                                        <td width={"40%"} align={"left"}>
                                            <p style={{ fontSize: "18px" }}>{fileName}</p>
                                        </td>
                                        <td width={"20%"}>
                                            <Button variant="contained" value={fileName} onClick={onDownloadButtonClick} >Download</Button>
                                        </td>
                                        <td width={"20%"} />
                                    </tr>
                                )
                            })
                        }
                        <tr>
                            <td align={"left"} colSpan={"4"}>
                                <p style={{ color: "red", fontSize: "18px" }}>{sOtherDownloadError}</p>
                            </td>
                        </tr>
                    </table>
                </CardContent>
            </Card>
        );
    }

    function renderEndUserLicenseAgreementLine(licenseAgreementLine) {
        return (<p>{licenseAgreementLine}</p>);
    }

    function renderEndUserLicenseAgreementTexts()
    {
        if (DownloadFunctions.EndUserLicenseAgreementTexts !== null && DownloadFunctions.EndUserLicenseAgreementTexts.length > 0) {
            return (DownloadFunctions.EndUserLicenseAgreementTexts.map(renderEndUserLicenseAgreementLine));
        }
    }

    function renderEndUserLicenseAgreementDialog() {
        return (
            <Dialog open={sShowEndUserLicenseAgreementDialog} fullWidth maxWidth="sm">
                <DialogTitle>End User License Agreement</DialogTitle>
                <DialogContent dividers={true} >
                <Card>
                    <CardContent  >
                        <table width={"100%"} cellpadding={"10"}>
                            <tr>
                                <td>
                                    <p>GDS DESIGN & DEVELOPMENT LTD.</p>
                                    <p>Copyright (C) 2012-{(new Date()).getFullYear()} GDS Design & Development Ltd.</p>
                                    {renderEndUserLicenseAgreementTexts()}
                                    <p>Copyright (C) 2012-{(new Date()).getFullYear()} GDS Design & Development Ltd.</p>
                                    <p>All trademarks and registered trademarks are property of their respective owners</p>
                                </td>
                            </tr>
                        </table>
                    </CardContent>
                    </Card>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={agreeLicenseAgreementButtonClick} >I Agree</Button>
                    <Button variant="contained" onClick={notAgreeLicenseAgreementButtonClick} >I Don't Agree</Button>
                </DialogActions>
            </Dialog>
        );
    }

    function renderTrialLicenseRegistration() {
        return (
            <Card>
                <CardContent >
                    <table width={"100%"} cellpadding={"10"}>
                        <tr>
                            <th className="Features-table-header" colspan={"3"}>
                                Or you can register for a trial license key.
                            </th>
                        </tr>
                        <tr>
                            <td className="Features-table-body" width={"40%"}>
                                <TextField multiline fullWidth id="emailTextField" value={sEmail} label="Email" onChange={onEmailChange} />
                            </td>
                            <td className="Features-table-body" width={"40%"}>
                                <TextField multiline fullWidth id="confirmedEmailTextField" value={sConfirmedEmail} label="Confirmed Email" onChange={onConfirmedEmailChange} />
                            </td>
                            <td width={"20%"}>
                                <Button variant="contained" disabled={sDisableRegisterButton} onClick={onRegisterButtonClick} >Register</Button>
                            </td>
                        </tr>
                        <tr>
                            <td className="Features-table-body" width={"40%"}>
                                <FormControl fullWidth>
                                    <InputLabel id="productIdInputLabel">Product</InputLabel>
                                    <Select id="productIdSelect" value={sProductId} label="Product" onChange={onProductIdChange}>
                                        <MenuItem value={ProductIdGdsGoogleMapWinForms}>{ProductNameGdsGoogleMapWinForms}</MenuItem>
                                        <MenuItem value={ProductIdGdsGoogleMapWpf}>{ProductNameGdsGoogleMapWpf}</MenuItem>
                                    </Select>
                                </FormControl>
                            </td>
                            <td width={"40%"} align={"left"} colSpan={"2"}>
                                <p style={{ color: "red" }}>{sRegisterError}</p>
                            </td>
                            <td width={"20%"}>
                            </td>
                        </tr>
                    </table>
                </CardContent>
            </Card>
        );
    }

    function renderCompleteTrialLicenseRegistration() {
        return (
            <Card>
                <CardContent >
                    <table width={"100%"} cellpadding={"10"}>
                        <tr>
                            <th className="Features-table-header" colspan={"2"}>
                                Please check your email and enter your registration confirmation code to complete the trial license registration.
                            </th>
                        </tr>
                        <tr>
                            <td className="Features-table-body" width={"80%"}>
                                <TextField multiline fullWidth id="registrationKeyField" label="Registration Confirmation Code" value={sRegistrationKey} onChange={onRegistrationKeyChange} />
                            </td>
                            <td width={"20%"}>
                                <Button variant="contained" disabled={sDisableGetKeyButton} onClick={onGetKeyButtonClick} >Get Key</Button>
                            </td>
                        </tr>
                        <tr>
                            <td className="Features-table-body" width={"80%"} align={"left"} colSpan={"2"}>
                                <p style={{ color: "red" }}>{sCompleteRegistrationError}</p>
                            </td>
                            <td width={"20%"} />
                        </tr>
                    </table>
                </CardContent>
            </Card>
        );
    }

    return (
        <div>
            {sShowProductsPage ? renderProductsPage() : renderLicenseInputPage()}
            {renderEndUserLicenseAgreementDialog()}
            <br />
            { sShowCompleteRegistrationUi ? renderCompleteTrialLicenseRegistration() : renderTrialLicenseRegistration() }
            <br/>
        </div>
    );
}

export default MapPageDownload;
