import './App.css';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

function Purchases() {

    return (
        <div  >
            <Card>
                <CardContent >
                    <br />
                    <div>
                        <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                            <input type="hidden" name="cmd" value="_s-xclick" />
                            <input type="hidden" name="hosted_button_id" value="P5UPBUZC56TKQ" />
                            <table>
                                <tr>
                                    <td className="Purchase-header"><input type="hidden" name="on0" value="Runtime Seats" />GDS Google Map WFM One Year Runtime Seats</td>
                                    <td >
                                        <select name="os0">
                                            <option value="WFM 20 Seats 1 Year">
                                                WFM 20 Seats 1 Year $500.00 USD
                                            </option>
                                            <option value="WFM 40 Seats 1 Year">
                                                WFM 40 Seats 1 Year $1,000.00 USD
                                            </option>
                                            <option value="WFM 60 Seats 1 Year">
                                                WFM 60 Seats 1 Year $1,500.00 USD
                                            </option>
                                            <option value="WFM 80 Seats 1 Year">
                                                WFM 80 Seats 1 Year $2,000.00 USD
                                            </option>
                                            <option value="WFM 100 Seats 1 Year">
                                                WFM 100 Seats 1 Year $2,500.00 USD
                                            </option>
                                            <option value="WFM 120 Seats 1 Year">
                                                WFM 120 Seats 1 Year $3,000.00 USD
                                            </option>
                                            <option value="WFM 140 Seats 1 Year">
                                                WFM 140 Seats 1 Year $3,500.00 USD
                                            </option>
                                            <option value="WFM 160 Seats 1 Year">
                                                WFM 160 Seats 1 Year $4,000.00 USD
                                            </option>
                                            <option value="WFM 180 Seats 1 Year">
                                                WFM 180 Seats 1 Year $4,500.00 USD
                                            </option>
                                            <option value="WFM 200 Seats 1 Year">
                                                WFM 200 Seats 1 Year $5,000.00 USD
                                            </option>
                                        </select>
                                    </td>
                                    <td>
                                        <input type="hidden" name="currency_code" value="USD" />
                                        <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_buynowCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Buy Now" />
                                    </td>
                                </tr>
                            </table>
                        </form>
                    </div>
                    <br />
                    <div>
                        <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                            <input type="hidden" name="cmd" value="_s-xclick" />
                            <input type="hidden" name="hosted_button_id" value="9B23R9DRX3C8W" />
                            <table>
                                <tr>
                                    <td className="Purchase-header"><input align={"left"} type="hidden" name="on0" value="Runtime Seats" />GDS Google Map WPF One Year Runtime Seats</td>
                                    <td>
                                        <select name="os0">
                                            <option value="WPF 20 Seats 1 Year">
                                                WPF 20 Seats 1 Year $500.00 USD
                                            </option>
                                            <option value="WPF 40 Seats 1 Year">
                                                WPF 40 Seats 1 Year $1,000.00 USD
                                            </option>
                                            <option value="WPF 60 Seats 1 Year">
                                                WPF 60 Seats 1 Year $1,500.00 USD
                                            </option>
                                            <option value="WPF 80 Seats 1 Year">
                                                WPF 80 Seats 1 Year $2,000.00 USD
                                            </option>
                                            <option value="WPF 100 Seats 1 Year">
                                                WPF 100 Seats 1 Year $2,500.00 USD
                                            </option>
                                            <option value="WPF 120 Seats 1 Year">
                                                WPF 120 Seats 1 Year $3,000.00 USD
                                            </option>
                                            <option value="WPF 140 Seats 1 Year">
                                                WPF 140 Seats 1 Year $3,500.00 USD
                                            </option>
                                            <option value="WPF 160 Seats 1 Year">
                                                WPF 160 Seats 1 Year $4,000.00 USD
                                            </option>
                                            <option value="WPF 180 Seats 1 Year">
                                                WPF 180 Seats 1 Year $4,500.00 USD
                                            </option>
                                            <option value="WPF 200 Seats 1 Year">
                                                WPF 200 Seats 1 Year $5,000.00 USD
                                            </option>
                                        </select>
                                    </td>
                                    <td>
                                        <input type="hidden" name="currency_code" value="USD" />
                                        <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_buynowCC_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Buy Now" />
                                    </td>
                                </tr>
                            </table>
                        </form>
                    </div>
                    <br />
                    <p className='Purchase-paypal-note'>PayPal takes credit card, thus you don't need to have a PayPal account to buy!</p>
                    <p className="Purchase-body">You will receive your license key through email by the end of next business day after your purchase.</p>
                </CardContent>
            </Card>
        </div>
    );
}

export default Purchases;
