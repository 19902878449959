import { useState } from "react";

import './App.css';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import MapPageSummary from "./MapPageSummary";
import MapPageFeatures from "./MapPageFeatures";
import MapPageScreencuts from "./MapPageScreencuts";
import MapPageDownload from "./MapPageDownload";

function MapPage() {
    const SummaryTabIndex = 0;
    const FeaturesTabIndex = 1;
    const ScreencutsTabIndex = 2;
    const DownloadTabIndex = 3;

    const [sTabValue, setTabValue] = useState(0);

    function onTabValueChange(e, newVal) {
        setTabValue(newVal);
    }

    return (
        <div>
            <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}>

                <Grid container spacing={3}>
                    <Grid item xs={2}>
                        <Tabs value={sTabValue} orientation="vertical" variant="scrollable" textColor="secondary" indicatorColor="secondary" onChange={onTabValueChange} sx={{ borderRight: 1, borderColor: 'divider' }} >
                            <Tab value={SummaryTabIndex} label="Summary" />
                            <Tab value={FeaturesTabIndex} label="Features" />
                            <Tab value={ScreencutsTabIndex} label="Screencuts" />
                            <Tab value={DownloadTabIndex} label="Download" />
                        </Tabs>
                    </Grid>
                    <Grid item xs>
                        <Box hidden={!(sTabValue === SummaryTabIndex)}>
                            <MapPageSummary />
                        </Box>
                        <Box hidden={!(sTabValue === FeaturesTabIndex)}>
                            <MapPageFeatures />
                        </Box>
                        <Box hidden={!(sTabValue === ScreencutsTabIndex)}>
                            <MapPageScreencuts />
                        </Box>
                        <Box hidden={!(sTabValue === DownloadTabIndex)}>
                            <MapPageDownload />
                        </Box>
                    </Grid>
                </Grid>

            </Box>
        </div>
    );
}

export default MapPage;
