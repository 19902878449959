import './App.css';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import image02 from './images/HP01.jpg';

function MapPageSummary() {

    return (
        <div>
            <Card>
                <CardContent >
                    <p className="Tab-Section-body" >
                        GDS Google Map is a .Net User Control, which integrates an interactive Google Map into your desktop applications,
                        providing a fast and convenient way for software developers to display customized geographic data on top of Google Map
                        and perform Google Map based operations and computations.
                    </p>
                    <br />
                    <p className="Tab-Section-body" >
                        GDS Google Map combines the power of .Net Desktop Control and Google Map API to provide an enhanced mapping experience. Currently,
                        there are two editions available: GDS Google Map WinForms and GDS Google Map WPF.
                    </p>
                    <br />
                    <img src={image02} className="Home-Image" alt="GdsGoogleMap" />
                </CardContent>
            </Card>
            <br />
        </div>
    );
}

export default MapPageSummary;
