import './App.css';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import unLockImg from './images/Unlock32.png';

function Licensing() {

    return (
        <div  >
            <Card>
                <CardContent >
                    <p className="Licensing-icon"><img src={unLockImg} alt={unLockImg} /></p>
                    <p className="Section-body">
                        For commercial usage, we charge runtime license or runtime seat for each device on which GDS assembly is running based on annual subscription.
                    </p>
                    <br />
                    <p className="Section-body">
                        The license key can be used on any device. However, at the same time, the total number of devices that GDS assembly is running on can not be more
                        than the total runtime seats that have been purchased.
                    </p>
                    <br />
                    <p className="Section-body">
                        Within the subscription period, the license key will work for all versions of the same edition of GDS assembly that has been purchased.
                    </p>
                    <br />
                    <p className="Section-body">
                        Within 30 days after the subscription expiry date, GDS assembly will turn into Commercial Trial Period and watermark will be displayed.
                    </p>
                    <br />
                    <p className="Section-body">
                        30 days after the subscription expiry date, GDS assembly will stop working and invalid license error will be displayed.
                    </p>
                    <br />
                    <p className="Licensing-icon"><img src={unLockImg} alt={unLockImg} /></p>
                    <p className="Section-body">
                        We offer a 30-day Trial Period for evaluating GDS assembly. The Trial Edition of GDS assembly contains limited functionalities and watermark will
                        be displayed.
                    </p>
                    <br />
                </CardContent>
            </Card>
        </div>
    );
}

export default Licensing;
